import classnames from 'classnames';
import useGlobalContext from '@/hooks/useGlobalContext';
import { screens } from '@/constants';
import { useTranslation } from '@/i18n';
import StyledSidebar from './styles/Sidebar';

const Sidebar = () => {
  const { t } = useTranslation();
  const { shrink, selectedScreen, navigateTo, logOut } = useGlobalContext();

  const handleClick = ({ currentTarget }) => {
    const { id } = currentTarget;
    if (id === screens.LOGIN) {
      logOut();
      return;
    }
    navigateTo(id);
  };

  const sidebarClassNames = classnames({ sidebar: true, show: shrink });

  const menuDashboardClassNames = classnames({
    sidebar__link: true,
    'text-black': true,
    selected: selectedScreen === screens.DASHBOARD || selectedScreen === screens.DETAIL,
  });

  const menuDashboardPath =
    selectedScreen === screens.DASHBOARD || selectedScreen === screens.DETAIL ? '-selected' : '';

  return (
    <StyledSidebar shrink={shrink}>
      <div className={sidebarClassNames}>
        <ul className="list-unstyled">
          <li>
            <div aria-hidden="true" id={screens.DASHBOARD} onClick={handleClick} className={menuDashboardClassNames}>
              <img alt="" className="sidebar__icon" src={`/img/icons/dashboard${menuDashboardPath}.png`} />
              <span>{t('menu.dashboard')}</span>
            </div>
          </li>
          <li>
            <div
              aria-hidden="true"
              id={screens.PLANS}
              onClick={handleClick}
              className={`sidebar__link text-black${selectedScreen === screens.PLANS ? ' selected' : ''}`}
            >
              <img
                alt=""
                className="sidebar__icon"
                src={`/img/icons/plans${selectedScreen === screens.PLANS ? '-selected' : ''}.png`}
              />
              <span>{t('menu.plans')}</span>
            </div>
          </li>
          <li>
            <div
              aria-hidden="true"
              id={screens.POINTS}
              onClick={handleClick}
              className={`sidebar__link text-black${selectedScreen === screens.POINTS ? ' selected' : ''}`}
            >
              <img
                alt=""
                className="sidebar__icon"
                src={`/img/icons/points${selectedScreen === screens.POINTS ? '-selected' : ''}.png`}
              />
              <span>{t('menu.points')}</span>
            </div>
          </li>
        </ul>
        <hr />
        <ul className="list-unstyled">
          <li>
            <div
              aria-hidden="true"
              id={screens.SETTINGS}
              onClick={handleClick}
              className={`sidebar__link text-black${selectedScreen === screens.SETTINGS ? ' selected' : ''}`}
            >
              <img
                alt=""
                className="sidebar__icon"
                src={`/img/icons/settings${selectedScreen === screens.SETTINGS ? '-selected' : ''}.png`}
              />
              <span>{t('menu.settings')}</span>
            </div>
          </li>
          <li>
            <div
              aria-hidden="true"
              id={screens.LOGIN}
              onClick={handleClick}
              className={`sidebar__link text-black${selectedScreen === screens.LOGIN ? ' selected' : ''}`}
            >
              <img
                alt=""
                className="sidebar__icon"
                src={`/img/icons/logout${selectedScreen === screens.LOGIN ? '-selected' : ''}.png`}
              />
              <span>{t('menu.logOut')}</span>
            </div>
          </li>
        </ul>
      </div>
    </StyledSidebar>
  );
};

export default Sidebar;
