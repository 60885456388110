import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
      --aqua: #10e5c0;
      --aqua-marine: #2af5d2;
      --black: #343434;
      --black-two: #000000;
      --blueBitsa: #517edb;
      --brown-grey-two: #828282;
      --brownish-grey: #646464;
      --cloudy-blue: #a7bad1;
      --cloudy-blue-hover: #acbed3;
      --dark: #153b68;
      --dark-sky-blue: #5887da;
      --dark-sky-blue-two: #538cde;
      --deep-red: #b80000;
      --gray-400: #ced4da;
      --gray-500: #adb5bd;
      --gray-hover: #f5f5f5;
      --lightBlueGreyFour: #c7c7cc;
      --orange: #f08900;
      --pale-grey: #f5f8fb;
      --paleLilac: #e5e5ea;
      --secundario: #4c79ba;
      --shadow: rgba(0, 0, 0, 0.1);
      --whiteTwo: #ffffff;
  }
  @font-face {
    font-family: 'exobold';
    src: url('/fonts/exo-bold-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'exoregular';
    src: url('/fonts/exo-regular-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
  }
  @font-face {
    font-family: 'exoblack';
    src: url('/fonts/exo-black-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
  }
  body{
    line-height: 1.5;
    font-weight: 400;
    font-size: 0.9rem;
    background-color: var(--pale-grey);
    font-family: 'exoregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  body::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--dark);
  }
  body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(167, 186, 209, 0.5);
  }
  h1,
  h2{
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    font-family: 'exoregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h2 {
    font-size: 1.8rem;
  }
  h5 {
    line-height: 1.2;
    font-weight: 800;
    font-size: 1.125rem;
    font-family: 'exoregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h6 {
    color: inherit;
    line-height: 1.2;
    font-size: 0.9rem;
    font-family: 'exoregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  .bitsa-button {
    border: none;
    display: flex;
    cursor: pointer;
    font-size: 0.9rem;
    color: var(--white);
    align-items: center;
    border-radius: 30px;
    padding: 0.5rem 1.6rem;
    box-shadow: none !important;
    background-color: #4285f4 !important;
    transition: all 0.15s ease-in-out;
    background: linear-gradient(to right, #348fde 0%, #7288f3 100%);
    font-family: 'exobold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  .bitsa-button.cancel {
    margin-right: 1.5rem;
    color: var(--dark);
    background: var(--pale-grey) !important;
  }
  .bitsa-button:focus {
    outline: 0;
  }
  .bitsa-button:active,
  .bitsa-button:not(:disabled):hover{
    background: #0b51c5 !important;
  }
  .bitsa-button.cancel:active,
  .bitsa-button.cancel:not(:disabled):hover{
    color: var(--white);
    background: var(--cloudy-blue) !important;
  }
  .bitsa-button:disabled {
    cursor: not-allowed;
    background: var(--paleLilac) !important;
  }
  .bitsa-swal-image {
    width: 35% !important;
  }
  .bitsa-swal-title {
     font-size: 1.6em !important;
     color: var(--dark) !important;
     margin-top: 1.5rem !important;
  }
  .bitsa-swal-title.mt-0 {
     margin-top: 0 !important;
  }
  .bitsa-swal-button {
    min-width: 167px;
    margin-bottom: 1rem;
  }
  .bitsa-swal-selection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .bitsa-swal-selection div,
  .bitsa-swal-selection div span {
    display: flex;
    align-items: center;
  }
  .bitsa-swal-selection div {
    width: 85%;
    cursor: pointer;
    margin-left: 2rem;
    padding: 1rem;
    transition: all 0.3s;
    justify-content: space-between;
    border-bottom: 1px solid var(--paleLilac);
  }
  .bitsa-swal-selection div:first-child {
    margin-top: 0.5rem;
  }
  .bitsa-swal-selection div:last-child {
    margin-bottom: 1rem;
  }
  .bitsa-swal-selection div:hover{
    color: var(--gray-500);
    background: var(--gray-hover);
  }
  .bitsa-swal-selection div .fa-chevron-right {
    color: var(--dark);
  }
  .bitsa-swal-transaction-detail-image {
    margin-bottom: 0.5rem !important;
  }
  .bitsa-transaction-detail {
    display: flex;
    flex-direction: column;

    .bitsa-transaction-detail__header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;

      .title,
      .amount {
        font-size: 1.5rem;
        line-height: 2rem;
        color: var(--dark);
        font-family: 'exobold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
      .amount {
        color: var(--blueBitsa);
      }
      .id {
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--gray-500);
        font-family: 'exoregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
    }
    .bitsa-transaction-detail__items {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 1rem;

      .item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 1rem;

        .label {
          font-size: 0.8rem;
          line-height: 1.5rem;
          color: var(--gray-500);
        }
        .value {
          color: var(--dark);
        }
      }
    }
    .bitsa-transaction-detail__footer {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 1rem;
      margin-bottom: 1rem;
      span:first-child {
        font-size: 1.2rem;
        line-height: 2rem;
        color: var(--blueBitsa);
        font-family: 'exobold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
      span:last-child {
        font-size: 0.9rem;
        color: var(--gray-500);
      }
    }
  }
  .swal2-close {
    outline: 0 !important;
  }
  .swal2-close:hover {
    color: var(--dark) !important;
  }
  .swal2-content {
    font-size: 1em !important;
  }
  .bitsa-swal-topUp-crypto {
    margin: 1rem 0;
    p {
      margin-bottom: 0;
      font-family: 'exoregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    span {
      margin-bottom: 0;
      font-size: 1.3rem;
      color: var(--dark);
      font-family: 'exobold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
  .bitsa-tooltip {
    .tooltip-inner{
      background-color: var(--orange);
      font-family: 'exoregular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    .arrow::before{
      border-left-color: var(--orange);
    }
  }
  .bitsa-swal-session-expired {
    span {
      font-family: 'exobold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
  .levels-swal-content {
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    img {
      width: 100%;
      height: 210px;
      margin-top: 1rem;
    }
    h4,
    .swal2-title {
      margin-top: 2rem;
      font-size: 1.6rem;
      line-height: 2rem;
      color: var(--dark);
      &.error{
        margin-top: 0;
      }
    }
    p {
      font-size: 0.9rem;
      text-align: center;
      color: var(--cloudy-blue);
    }
    @media (min-width: 769px) {
      .lottie-container {
        margin-right: 1.3rem;
      }
    }
  }
  .bitsa-levels-button {
    width: 90%;
    height: 45px;
    font-size: 1rem;
  }
  .text-primary {
    color: var(--dark-sky-blue-two);
  }
  .text-primary:hover,
  .text-primary:focus {
    color: #135dff;
  }
  .text-black {
    color: #515151;
  }
  .text-gray {
    color: var(--gray-500);
  }
  .text-gray-400 {
    color: var(--gray-400);
    transition: color 0.3s;
  }
  .text-gray-400:hover,
  .text-gray-400:focus {
    color: #b1bbc4;
  }
  .text-gray-500 {
  color: var(--gray-500);
  transition: color 0.3s;
  }
  .text-gray-500:hover,
  .text-gray-500:focus {
    color: #919ca6;
  }
  .text-blue {
    color: #4C84FF;
  }
  .text-blue:hover {
    color: #1961ff;
  }
  .text-uppercase {
    letter-spacing: 0.2em;
  }
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  .px-6 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  input:not(.read-only):disabled,
  .bitsa-disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
  ::selection {
    text-shadow: none;
    color: var(--white);
    background: #7187f2;
  }
  @media (min-width: 1200px){
    .px-6 {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }
  @media (max-width: 425px) {
    .bitsa-swal-image {
      width: 50% !important;
    }
  }
`;

export default GlobalStyle;
