import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { Provider as ReactProvider } from '@/context/globalContext';
import { screens } from '@/constants';
import { removeToken } from '@/utils/cookies';

const GlobalProvider = ({ token, initialLanguage, children }) => {
  const router = useRouter();
  const [shrink, setShrink] = useState(false);
  const route = useMemo(
    () => (router.pathname === '/' || router.pathname === screens.DASHBOARD ? screens.DASHBOARD : router.pathname),
    [router.pathname]
  );
  const [selectedScreen, setSelectedScreen] = useState(route);

  const logOut = () => {
    setSelectedScreen(screens.LOGIN);
    removeToken();
    router.push(screens.LOGIN);
  };

  const toggleShrink = () => setShrink(!shrink);

  const navigateTo = (option, card = null) => {
    setSelectedScreen(option);
    router.push({
      pathname: card ? screens.DETAIL : option,
      ...(option === screens.DETAIL && card !== null && { query: { id: card.id } }),
    });
  };

  return (
    <ReactProvider
      value={{
        token,
        initialLanguage,
        logOut,
        shrink,
        toggleShrink,
        selectedScreen,
        navigateTo,
      }}
    >
      {children}
    </ReactProvider>
  );
};

GlobalProvider.defaultProps = {
  token: '',
};

GlobalProvider.propTypes = {
  token: PropTypes.string,
  initialLanguage: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default GlobalProvider;
