import { cloneElement } from 'react';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import Spinner from '@/components/Spinner';
import Navbar from '@/components/Navbar';
import Sidebar from '@/components/Sidebar';
import Footer from '@/components/Footer';
import StyledLayout from '@/containers/styles/Layout';
import { internalApiPaths } from '@/constants';
import { swrFetcher } from '@/utils/api';

const Layout = ({ children }) => {
  const { token } = children.props;

  const { data } = useSWR([internalApiPaths.ACCOUNT_ME, 'GET', token], swrFetcher);
  if (!data) {
    return <Spinner />;
  }

  return (
    <>
      <Navbar accountMe={data} />
      <div className="d-flex">
        <Sidebar />
        <StyledLayout>
          {cloneElement(children, { accountMe: data })}
          <Footer />
        </StyledLayout>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
