import PropTypes from 'prop-types';
import { Provider } from '@/context/plansContext';
import { internalApiPaths } from '@/constants';
import useSWR from 'swr';
import { swrFetcher } from '@/utils/api';

const PlansProvider = ({ children }) => {
  const { data } = useSWR([internalApiPaths.FETCH_PLANS, 'GET', ''], swrFetcher);

  return (
    <Provider
      value={{
        plans: data || {},
      }}
    >
      {children}
    </Provider>
  );
};

PlansProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PlansProvider;
