import App from 'next/app';
import { SWRConfig } from 'swr';
import * as Sentry from '@sentry/node';
import { appWithTranslation } from '@/i18n';
import Meta from '@/components/Meta';
import GlobalStyle from '@/styles/GlobalStyle';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import GlobalProvider from '@/providers/GlobalProvider';
import Layout from '@/layouts/Layout';
import { version } from '@/package.json';
import PlansProvider from '@/providers/PlansProvider';

Sentry.init({
  release: version,
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.SENTRY_ENV,
  environment: process.env.SENTRY_ENV,
});

class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    if (process.env.SENTRY_ENV) {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        Sentry.captureException(error);
      });
    }
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <SWRConfig
        value={{
          refreshInterval: 0,
          revalidateOnFocus: false,
        }}
      >
        <Meta />
        <GlobalStyle />
        <GlobalProvider token={pageProps.token} initialLanguage={pageProps.initialLanguage}>
          {pageProps.token ? (
            <PlansProvider>
              <Layout>
                <Component location={pageProps.location} token={pageProps.token} />
              </Layout>
            </PlansProvider>
          ) : (
            <Component />
          )}
        </GlobalProvider>
      </SWRConfig>
    );
  }
}

export default appWithTranslation(MyApp);
