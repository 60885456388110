import styled from 'styled-components';

export default styled.div`
  z-index: 1;

  .sidebar {
    width: ${({ shrink }) => (shrink ? '9rem' : '14rem')};
    height: 100%;
    padding-top: 2.5rem;
    background: var(--white);
    box-shadow: 0 0.125rem 0.8rem var(--shadow);
    transition: all 0.3s ease-out;
  }
  .sidebar.show {
    margin-left: 0;
  }
  .sidebar ul:first-child {
    margin-bottom: 2rem;
  }
  .sidebar__link {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0.5rem 1.5rem;
    transition: background 0.3s;
    flex-direction: ${({ shrink }) => (shrink ? 'column' : 'row')};
  }
  .sidebar__link:hover {
    color: var(--gray-500);
    background: var(--gray-hover);
    text-decoration: none;
  }
  .sidebar__link:focus,
  .sidebar__link.selected {
    color: var(--white);
    background: var(--dark-sky-blue-two);
    text-decoration: none;
  }
  .sidebar__link:focus i,
  .sidebar__link.selected i,
  .sidebar__link:focus::before,
  .sidebar__link.selected::before {
    color: var(--white);
  }
  .sidebar__link i {
    min-width: 30px;
    font-size: 1.5rem;
    text-align: center;
  }
  .sidebar__link span {
    margin-left: ${({ shrink }) => (shrink ? 0 : '1rem')};
    display: inline-block;
  }
  .sidebar.shrink {
    width: auto;
    text-align: center;
  }
  .sidebar.shrink .sidebar__link {
    flex-direction: row;
  }
  .sidebar.shrink .sidebar__link span {
    display: block;
    font-size: 0.75rem;
  }
  .sidebar.shrink .sidebar__link i {
    margin: 0 !important;
  }
  .sidebar.shrink .sidebar__icon {
    margin-right: 0 !important;
    margin-bottom: 0.2rem;
  }
  hr {
    width: 80%;
    background: var(--paleLilac);
  }
  @media (min-width: 1025px) {
    .sidebar.show {
      width: 100%;
    }
    .sidebar.show .sidebar__link span {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .sidebar {
      width: 9rem;
      text-align: center;
      margin-left: -9rem;
      transition: all 0.4s;
    }
    .sidebar .sidebar__link {
      display: block;
    }
    .sidebar .sidebar__link span {
      display: block;
      font-size: 0.75rem;
    }
    .sidebar .sidebar__link i {
      margin: 0;
      flex-direction: column;
    }
  }
  @media (max-width: 1024px) {
    height: 100%;
    position: absolute;
  }
`;
